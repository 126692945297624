import React from "react";

const TableRow = ({ row }) => {
  return (
    <tr>
      {row.map((val, index) => (
        <td className={index !== 0 ? `txt-red` : null} key={index}>
          <span>{val}</span>
        </td>
      ))}
    </tr>
  );
};

const Menu = ({ menu, className, image }) => {
  return (
    <div className={`garlic-item ${className}`}>
      <div className="favorite-item-box">
        <h1>{menu.heading}</h1>
        {image && <img src={image} alt="" />}

        <p>{menu.description}</p>
        <div className="fvrt-item-table">
          <table className="table">
            <thead>
              <tr>
                {menu.tableHeading &&
                  menu.tableHeading.map((item, index) => {
                    return (
                      <th scope="col" key={index}>
                        {item}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {menu.tableContent &&
                menu.tableContent.map((content, index) => {
                  return <TableRow key={index} row={content} index={index} />;
                })}
            </tbody>
          </table>
        </div>
      </div>

      {menu.subItems &&
        menu.subItems.map((item, index) => {
          return (
            <div className="favorite-text-box" key={index}>
              <h4>{item?.title}</h4>

              <div className="fvrt-item-list">
                <p>{item?.description}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Menu;
