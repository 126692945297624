import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Menu from "../components/PizzaMenus/Menu";
import MenuList from "../components/PizzaMenus/MenuList";
import { data } from "../data";

const Donairs = () => {
  return (
    <Layout title="Tasty Pizza – Donairs Menu">
      <PageBanner title={data.donairs.pageHeading} image={data.donairs.image} />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 margin-top">
            <Menu menu={data.donairs.donairMenu} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <MenuList menu={data.donairs.donairPlate} />
          </div>
          <div className="col-lg-6">
            <MenuList menu={data.donairs.donairSauce} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <MenuList menu={data.donairs.donairPogos} />
          </div>
          <div className="col-lg-6">
            <MenuList menu={data.donairs.donairEggRolls} />
          </div>
        </div>

        <div className="row donair">
          <div className="col-lg-4">
            <StaticImage
              src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623708097/Tasty%20Pizza/D1_zyeyax.jpg"
              alt="Tasty Pizza"
            />
          </div>
          <div className="col-lg-4">
            <StaticImage
              src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623708266/Tasty%20Pizza/DP1_voeeqw.jpg"
              alt="Tasty Pizza"
            />
          </div>
          <div className="col-lg-4">
            <StaticImage
              src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623708092/Tasty%20Pizza/D3_wuwfst.jpg"
              alt="Tasty Pizza"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Donairs;
